import lodash from "lodash";
import Resizer from "react-image-file-resizer";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { PromotionType, SUCCESS_UPDATE_MESSAGE } from "./helper-contants";
import Token from "./Token";

const TIME_LABELS = {
  0: "0 min",
  0.25: "15 min",
  0.5: "30 min",
  0.75: "45 min",
  1: "1h",
  2: "2h",
  3: "3h",
  4: "4h",
  6: "6h",
  12: "12h",
  24: "1j",
  48: "2j",
  72: "3j",
};
const ORDER_TIME_LABELS = {
  0.25: "15 min",
  0.5: "30 min",
  0.75: "45 min",
  1: "1h",
  2: "2h",
  3: "3h",
  4: "4h",
  6: "6h",
  12: "12h",
  24: "1j",
  48: "2j",
  72: "3j",
  96: "4j",
  120: "5j",
  144: "6j",
  168: "7j",
  192: "8j",
};
export const getExtentionFile = (filename) => {
  if (!filename || filename?.indexOf(".") == -1) return "";
  return filename?.split(".")[1];
};
export const renderTimeBeforeDeliver = (nb) => {
  return TIME_LABELS[nb];
};
export const renderTimeOrderLifeTime = (nb) => ORDER_TIME_LABELS[nb];
export const isFrame = (token) => {
  if (!token) {
    return null;
  }
  const { isFrame } = jwtDecode(token);
  return !!isFrame;
};
export const getOriginToken = () => localStorage.getItem("originTokenpart");
export const stripTypenames = (value) => {
  if (Array.isArray(value)) {
    return value.map(stripTypenames);
  }
  if (value !== null && typeof value === "object") {
    const newObject = {};
    for (const property in value) {
      if (property !== "__typename") {
        newObject[property] = stripTypenames(value[property]);
      }
    }
    return newObject;
  }
  return value;
};
export const makeId = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const makeCode = (length) => {
  let result = "RIVALIS";
  const characters = "0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const removeFirstAndLast = (str) => {
  if (str && str.length > 0) {
    const tab = str.split("");
    const n = tab.filter((c) => c !== '"');
    return n.join("");
  }
  return "";
};
export const isLogistic = () => Token.getRole() === "LOGISTIC";
export const formatToMultiSelectData = (data) => {
  const array = [];
  if (data && data.length > 0) {
    data.forEach((category) => {
      if (category.status && category?.subCategories?.length > 0) {
        category.subCategories.forEach((subcat) => {
          array.push({
            key: subcat.name,
            cat: category?.name,
            subCategoryId: subcat.id,
            categoryId: category?.id,
          });
        });
      }
    });
  }
  return array;
};
export const formatToMultiSelectDataNew = (data) => {
  const array = [];
  if (data && data.length > 0) {
    data.forEach((category) => {
      if (category.status && category?.subCategories?.length > 0) {
        const options = category.subCategories.map((subcat) => ({
          value: subcat.name,
          label: subcat?.name,
          cat: category?.name,
          subCategoryId: subcat.id,
          categoryId: category?.id,
        }));
        array.push({
          label: category?.name,
          options,
        });
      }
    });
  }
  return array;
};

export const reverseSubCategoryToCategory = (data) => {
  let parent = data && data.map((cat) => cat.category);
  parent = lodash.uniqBy(parent, "id");

  const cats = parent.map((cat) => {
    const list_sub_categories = [];
    data.map((sub) => {
      if (sub?.category?.id === cat.id) {
        list_sub_categories.push(sub);
      }
    });
    return { id: cat.id, name: cat.name, subCategories: list_sub_categories };
  });
  return cats;
};
export const isAuthentificated = () =>
  localStorage.getItem("tokenpart") && Token.getRole() !== null;

export const resizeFile = (file = null) =>
  new Promise((resolve) => {
    if (!file) return resolve(null);

    Resizer.imageFileResizer(
      file,
      800,
      430,
      "JPEG",
      100,
      0,
      (uri) => resolve(uri),
      "file",
      800,
      400
    );
  });
export const resizeFileWithDimention = (
  file = null,
  height = 150,
  width = 200
) =>
  new Promise((resolve) => {
    if (!file) return resolve(null);

    Resizer.imageFileResizer(
      file,
      width,
      height,
      "JPEG",
      100,
      0,
      (uri) => resolve(uri),
      "file",
      width,
      height
    );
  });
export const validPrice = (ch) => {
  if (!ch?.length) return true;
  /*   const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", ","];
  const x = ch.replaceAll(",", ".");
  return String(x).split``.every((n) => numbers.includes(n)); */
  var x = ch.replaceAll(",", ".");
  var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
  if (!floatRegex.test(x)) return false;

  x = parseFloat(x);
  if (isNaN(x)) return false;
  return true;

  // return ch?.length === String(+ch).length;
};
export const getPrice = (n) => {
  if (!n) return 0;
  const x = `${n}`.replace(",", ".");
  console.log({ x });
  return +x;

  // return ch?.length === String(+ch).length;
};
export const validateVariation = (varis = []) => {
  if (!varis?.length) return false;
  return varis?.every((variant) =>
    [validPrice(variant?.price), validPrice(variant?.weight)].every(Boolean)
  );
};
export const getPriceAfterPromotion = ({ price, promotion }) => {
  let newPrice = price;

  if (promotion.promotionType === PromotionType.POURCENT) {
    newPrice -= (newPrice * promotion.value) / 100;
  }
  if (promotion.promotionType === PromotionType.FIXED_AMOUNT) {
    newPrice -= promotion.value;
  }
  return newPrice;
};
export const showSuccessUpdateMessage = (labels) => {
  toast.success(eval(SUCCESS_UPDATE_MESSAGE));
};
export const showSuccessPayment = (labels = null) => {
  toast.success("Votre paiement a été effectué avec succès");
};
export const isThereAnActivePromotion = (data) => {
  var test = false;
  data.map((item) => {
    if (item?.enabled) test = true;
  });
  return test;
};

export const totalAverage = (...ratingItems) => {
  const nestedItems = [];
  ratingItems.forEach((item) => {
    if (typeof item.total === "undefined")
      Object.keys(item).forEach((key) => {
        if (key !== "__typename") nestedItems.push(item[key]);
      });
  });
  const items = [
    ...ratingItems.filter((item) => typeof item.total !== "undefined"),
    ...nestedItems,
  ];
  let sum = 0;
  const avgs = items.map((item) => {
    sum += item.coef;
    return item.total === 0 ? 0 : (item.partial / item.total) * item.coef;
  });
  return (avgs.reduce((a, b) => a + b) / sum) * 5;
};

export const averageSystem = () => {
  return 5;
};

export const average = (obj) => {
  if (obj.total == 0) return 0;
  else {
    let moy = obj.partial / obj.total;
    return moy;
  }
};

export function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const getDeletedBy = (deletedBy) => {
  switch (deletedBy) {
    case "MANAGER":
      return "Résponsable";
    case "LOGISTIC":
      return "Administrateur";
    case "SUPER_ADMIN":
      return "Administrateur";
    case "DRIVER":
      return "Livreur";
    case "CUSTOMER":
      return "Client";
    default:
      return "";
  }
};

export const formattingMobile = (mobile) => {
  if (!mobile) return null;
  const countryCode = mobile.substring(0, 3);
  return mobile.length === 13
    ? `${countryCode}${mobile.substring(3, 4)}${mobile.substring(
        4,
        6
      )}${mobile.substring(6, 8)}${mobile.substring(8, 11)}${mobile.substring(
        10,
        12
      )}`
    : `${countryCode}${mobile.substring(3, 5)}${mobile.substring(
        5,
        7
      )}${mobile.substring(7, 9)}${mobile.substring(9, mobile.length)}`;
};
