import React from "react";
import * as Icon from "react-feather";
import { UilNotes } from "@iconscout/react-unicons";

const horizontalMenuConfig = [
  {
    id: "home",
    title: "home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["LOGISTIC", "PARTNER"],
    navLink: "/",
  },
  {
    id: "commands",
    title: "orders",
    type: "item",
    icon: <UilNotes size={20} />,
    permissions: ["LOGISTIC", "PARTNER"],
    navLink: "/commandes",
  },
];

export default horizontalMenuConfig;
